import { proxied } from 'radash';
import { CurrencyEnum, THBFormat, VND1000Format } from '~constants/etc';

const moneyFormat = (currency: CurrencyEnum): string => {
  const moneyFormats = proxied((prop) => {
    switch (prop) {
      case CurrencyEnum.VND:
        return VND1000Format;
      case CurrencyEnum.THB:
        return THBFormat;
      default:
        return THBFormat;
    }
  });
  return moneyFormats[currency];
};

export default moneyFormat;
