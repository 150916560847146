import type { FC } from 'react';
import numeral from 'numeral';
import { useUserInfo } from '~hooks/use-user';
import { useWalletInfo } from '~hooks/use-wallet';
import { convertCurrency } from '~libs/convert-currency';
import { DEFAULT_CURRENCY } from '~constants/etc';
import moneyFormat from '~libs/money-format';

const BalanceWidget: FC<{
  className?: string;
  balance?: number | undefined;
}> = ({ className, balance }) => {
  const { data: userInfo } = useUserInfo();
  const { data: walletInfo } = useWalletInfo(
    userInfo?.selectedWallet?.objectId || '',
  );
  const currency = walletInfo?.currency || DEFAULT_CURRENCY;

  return (
    <span className={className}>
      {numeral(convertCurrency(balance, currency)).format(
        moneyFormat(currency),
      )}
    </span>
  );
};

export default BalanceWidget;
